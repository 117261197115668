var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "wp-page recipe-list", on: { click: _vm.clicked } },
    [
      _c("breadcrumb", { attrs: { items: _vm.breadcrumb } }),
      _vm.page
        ? _c(
            "div",
            { staticClass: "recipe-header mb-2" },
            [
              _c(
                "v-img",
                {
                  staticClass: "d-flex justify-center align-center rounded",
                  attrs: {
                    src: _vm.page.featured_image_url,
                    heigth: "250",
                    "min-height": "250"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "pa-10 white rounded-circle d-flex align-center justify-center recipe-header-icon"
                    },
                    [
                      _c("v-img", {
                        attrs: {
                          "min-width": "42",
                          src: `/img_layout/wordpress/${_vm.cuisine}.svg`
                        }
                      })
                    ],
                    1
                  ),
                  _c("h1", {
                    staticClass: "text-center white--text",
                    domProps: { innerHTML: _vm._s(_vm.title) }
                  }),
                  _c("div", {
                    staticClass: "text-center white--text",
                    domProps: { innerHTML: _vm._s(_vm.content) }
                  })
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.cuisine === "appuntino"
        ? _c(
            "v-row",
            { staticClass: "d-flex justify-end" },
            [_c("RecipeFilterLite")],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        _vm._l(_vm.postList, function(recipe) {
          return _c(
            "v-col",
            {
              key: recipe.id,
              attrs: { cols: "12", sm: "6", md: "4", lg: "3", xl: "3" }
            },
            [_c("PostCard", { attrs: { post: recipe, isRecipe: true } })],
            1
          )
        }),
        1
      ),
      _vm.pager && _vm.pager.totPages > 1
        ? _c("v-pagination", {
            staticClass: "mt-12",
            attrs: {
              color: "secondary",
              length: _vm.pager.totPages ? _vm.pager.totPages : 0,
              totalVisible: _vm.$vuetify.breakpoint.xs ? 4 : 6
            },
            on: {
              next: function($event) {
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.handlePageFilter.apply(null, arguments)
              },
              prev: _vm.handlePageFilter,
              input: _vm.handlePageFilter
            },
            model: {
              value: _vm.pageNum,
              callback: function($$v) {
                _vm.pageNum = $$v
              },
              expression: "pageNum"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }