var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.coursesData
    ? _c(
        "div",
        { staticClass: "recipe-detail-filter ma-2 mt-4" },
        [
          _c("v-select", {
            staticClass: "white default--text mb-3 mb-md-0",
            attrs: {
              outlined: "",
              "hide-details": "",
              "single-line": "",
              "return-object": "",
              clearable: "",
              items: _vm.coursesData,
              "item-text": "name",
              "item-value": "slug",
              label: _vm.$t("recipes.filter.portata")
            },
            on: {
              change: function($event) {
                return _vm.filterByCourse(_vm.course)
              }
            },
            model: {
              value: _vm.course,
              callback: function($$v) {
                _vm.course = $$v
              },
              expression: "course"
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }